
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









































































.about__component {
  margin-top: var(--spacing-l) !important;
}
